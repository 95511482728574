
.map_pop_window div.title {
    font-size: 14px;
    font-weight: bold;
}

.map_pop_window span.notice {
    font-size: 12px;
    font-weight: normal;
}

.map_pop_window table.popup_road_detail th {
    border-color: #d8d8d8;
    border-style: solid;
    border-width: 0 0 1px 0;
    text-align: left;
    min-width: 150px;
}

.map_pop_window table.popup_road_detail td {
    border-color: #d8d8d8;
    border-style: solid;
    border-width: 0 0 1px 0;
    min-width: 110px;
}

.maplibregl-popup {
    max-width: 320px !important;
}
